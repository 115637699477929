<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      head-title="题库基本信息"
      @head-save="headSave"
      @head-save-back="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm.sync="dataForm"
    ></form-layout>
    <head-layout
      ref="headLayout"
      :head-btn-options="tableHeadOption"
      head-title="题目选项明细"
      @head-remove="headRemove"
      @head-add-tabs="headAdd"
    ></head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      @row-remove="rowRemove"
    >
    </grid-layout>
  </div>
</template>

<script>

import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {getEduTree} from "@/api/training/edutree";
import {checkNameExist, getDetail, update} from "@/api/training/eduquestions";
import {getLineList, removeLine} from "@/api/training/eduquestionsanswers";

export default {
  name: "eduquestionsEdit",
  components: {
    HeadLayout,
    FormLayout,
    GridLayout,
  },
  data() {
    return {
      type: "",
      dataForm: {},
      tableData: [],
      tableLoading: false,
      saveLoading: false,
      testPaperType:"",
      query: {},
      tableHeadOption: [],
      defaultValues : ['A', 'B', 'C', 'D', 'E','F','G','H','I','J'],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
    }
  },
  computed: {
    headBtnOptions() {
      return [
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: 'save',
          loading: this.saveLoading,
        },
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-save-back",
          type: "button",
          icon: "",
          btnOptType: 'saveBack',
          loading: this.saveLoading,
        },
        {
          label: this.$t('cip.cmn.btn.cancel'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
          loading: this.saveLoading,
        }
      ]
    },
    formColumn() {
      return [
        {
          label: "编号",
          prop: "code",
          slot: true,
          search: true,
          sortable: true,
          readonly: true,
          width: 180,
          placeholder: "保存后自动生成",
          rules: [{
            required: false,
            message: "请输入编号",
            trigger: ["blur", "change"]
          }]
        },
        {
          label: "分类",
          prop: "subjectType",
          sortable: true,
          dicData: [],
          type: "tree",
          props: {
            label: "title",
            value: "value",
          },
          rules: [{
            required: true,
            message: "请输入分类",
            trigger: ["blur", "change"]
          }]
        },

        // {
        //   label: "出题依据",
        //   prop: "accordingTo",
        //   sortable: true,
        //   search: true,
        //   type: 'select',
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        //   rules: [{
        //     required: true,
        //     message: "请输入出题依据",
        //     trigger: ["blur", "change"]
        //   }]
        // },
        // {
        //   label: "考试级别",
        //   prop: "lexaminationLevel",
        //   sortable: true,
        //   search: true,
        //   type: 'select',
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        //   rules: [{
        //     required: true,
        //     message: "请输入考试级别",
        //     trigger: ["blur", "change"]
        //   }]
        // },
        {
          label: "难易度",
          prop: "facilityType",
          sortable: true,
          search: true,
          type: 'select',
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_difficult_level',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
          rules: [{
            required: true,
            message: "请输入难易度",
            trigger: ["blur", "change"]
          }]
        },
        {
          label: "题目类型",
          prop: "topicType",
          sortable: true,
          search: true,
          type: 'select',
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=exam_question_type',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
          change: (value, column) => {
            this.topicChange(value, column);
          },
          // value: '1',
          rules: [{
            required: true,
            message: "请输入题目类型",
            trigger: ["blur", "change"]
          }]
        },
        {
          label: "题目名称",
          prop: "subjectName",
          type: "textarea",
          search: true,
          sortable: true,
          maxlength: 150,
          span: 24,
          minRows: "2",
          maxRows: "4",
          showWordLimit: true,
          rules: [{
            required: true,
            message: "请输入题目名称",
            trigger: ["blur", "change"]
          }],
          blur: (row,a,c) => {
            checkNameExist(row.value, this.dataForm.id).then(res=>{
              if (res.data == false) {
                this.flag = false;
                this.saveLoading = false;
                this.$refs.formLayout.$refs.form.allDisabled = false
                return this.$message({
                  type: "warning",
                  message: "题目名称不能重复!",
                });
              }
            })
          }
        },
        {
          label: "题目解析",
          prop: "analysis",
          sortable: true,
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 500,
          showWordLimit: true,
        },
        {
          label: "备注",
          prop: "note",
          sortable: true,
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 100,
          showWordLimit: true,
          rules: [{
            required: false,
            message: "请输入备注",
            trigger: ["blur", "change"]
          }]
        },
      ]
    },
    tableOptions() {
      return {
        menu: true,
        column: [
          {
            label: "选项",
            prop: "answerOption",
            cell: true,
            sortable: true,
            //readonly: true,
            maxlength: 20,
            showWordLimit: true,
            width: 250,
            rules: [{
              required: true,
              message: "请输入选项",
              trigger: ["blur", "change"]
            }]
          },
          {
            label: "选项值",
            prop: "answerOptionValue",
            cell: true,
            sortable: true,
            maxlength: 100,
            showWordLimit: true,
            minWidth: 250,
            rules: [{
              required: true,
              message: "请输入选项值",
              trigger: ["blur", "change"]
            }]
          },
          {
            label: "是否正确答案",
            prop: "answerRight",
            cell: true,
            sortable: true,
            type: "select",
            dicUrl: '/api/sinoma-system/dict/dictionary?code=yes_no',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 150,
            align: 'center',
            dataType: 'number',
            rules: [{
              required: true,
              message: "请输入是否正确答案",
              trigger: ["blur", "change"]
            }]
          },
        ],
      }
    },
    gridRowBtn() {
      return [
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "row-remove",
          type: "button",
          icon: "",
        }
      ]
    },
  },
  methods: {
    topicChange(value) {
      if (value.value == "3") {
        this.$refs.gridLayOut.option.menu = false;
        this.viewFlag = true;
        this.$refs.gridLayOut.tableData = [];
        let data = [
          {
            answerOption: "A",
            answerOptionValue: "正确",
            answerRight: "1",
            listIndex: this.listIndex,
          },
          {
            answerOption: "B",
            answerOptionValue: "错误",
            answerRight: "0",
            listIndex: this.listIndex,
          }
        ]
        this.$refs.gridLayOut.tableData = data;
        this.tableData = data;
        if (this.data != undefined) {
          for (let i = 0; i < this.data.length; i++) {
            this.$refs.gridLayOut.tableData[i].$cellEdit = true;
          }
        } else {
          this.$refs.gridLayOut.tableData.forEach((item) => {
            item.$cellEdit = true;
          })
          this.tableHeadOption = [];
          this.$refs.gridLayOut.tableOptions.column[0].cell = false;
          this.$refs.gridLayOut.tableOptions.column[1].cell = false;
        }
      } else {
        this.$refs.gridLayOut.option.menu = true;
        this.viewFlag = false;
        this.$refs.gridLayOut.tableData = [];
        this.$refs.gridLayOut.tableOptions.column[0].cell = true;
        this.$refs.gridLayOut.tableOptions.column[1].cell = true;
        this.tableHeadOption = [
          {
            label: this.$t('cip.cmn.btn.addBtn'),
            emit: "head-add-tabs",
            type: "button",
            icon: "",
            btnOptType: 'add',
          },
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "head-remove",
            type: "button",
            icon: "",
            btnOptType: 'remove',
          }
        ]
      }
    },
    headSave(clo = false) {
      this.checkForm(() => {
        this.$loading();
        // let form = this.$refs.formLayout.dataForm;
        let form = this.dataForm;
        form.eduQuestionsAnswersList = this.$refs.gridLayOut.tableData;
        this.saveLoading = true
        checkNameExist(form.subjectName, form.id).then(
          (res) => {
            if (res.data == false) {
              this.flag = false;
              this.saveLoading = false;
              this.$refs.formLayout.$refs.form.allDisabled = false
              this.$message({
                type: "warning",
                message: "题目名称不能重复!",
              });
            } else {
              this.saveLoading = true
              form.testPaperType = this.testPaperType
              update(form).then(
                (res) => {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  this.$refs.formLayout.dataForm = res.data.data;
                  this.dataForm = res.data.data;
                  this.$refs.gridLayOut.tableData = res.data.data.eduQuestionsAnswersList;
                  this.$refs.gridLayOut.selectionClear()
                  for (let i = 0; i < this.$refs.gridLayOut.tableData.length; i++) {
                    this.$refs.gridLayOut.tableData[i].$cellEdit = true;
                  }
                  if (clo) {
                    this.headCancel();
                  }
                  this.saveLoading = false;
                }
              ).finally(() => {
                this.saveLoading = false
                this.$refs.formLayout.$refs.form.allDisabled = false
              });
            }
          }
        ).catch(() => {
          this.saveLoading = false
          this.$refs.formLayout.$refs.form.allDisabled = false
        });
        this.$loading().close();
      })
    },
    checkForm(callBack) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.gridLayOut.getGrid().validateCellForm()
            .then(res => {
              let result = true
              if (this.$refs.gridLayOut.tableData.length === 0) {
                result = false
                this.$message.warning("题目选项明细不能为空")
              }
              for (let i = 0; i < this.$refs.gridLayOut.tableData.length; i++) {
                Object.keys(res).forEach(ele => {
                  if (ele.indexOf(`list.${i}`) !== -1) {
                    result = false
                    if (res[ele].length > 0) {
                      this.$message.warning(res[ele][0]["message"])
                    }
                  }
                })
              }
              if (result) {
                let tableData = this.$refs.gridLayOut.tableData
                let hasA = false
                for (let i = 0; i < tableData.length; i++) {
                  if (tableData[i]['answerRight'] == '1') {
                    hasA = true;
                    break
                  }
                }
                if (hasA) {
                  if (this.dataForm.topicType == 1) {
                    let rightList = tableData.filter((item) => {
                      return item.answerRight == 1
                    });
                    if (rightList.length > 1) {
                      this.$refs.formLayout.$refs.form.allDisabled = false
                      this.$message.warning("单选题只能选一个正确答案");
                      return;
                    }
                    callBack()
                  } else if (this.dataForm.topicType == 3) {
                    let rightList = tableData.filter((item) => {
                      return item.answerRight == 1
                    });
                    if (rightList.length > 1) {
                      this.$refs.formLayout.$refs.form.allDisabled = false
                      this.$message.warning("判断题只能选一个正确答案");
                      return;
                    }
                    callBack()
                  } else {
                    callBack()
                  }
                } else {
                  this.$refs.formLayout.$refs.form.allDisabled = false
                  this.$message.warning("至少选择一个正确答案")
                }
              } else {
                this.$refs.formLayout.$refs.form.allDisabled = false
              }
            }).catch(err => {
          })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let indexList = selectionList.map(e => e.$index).sort((a, b) => b - a)
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      if (selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (ids.length === 0) {
          indexList.forEach(index => {
            this.rowDelete(index)
          })
          this.$refs.gridLayOut.selectionClear();
        } else {
          removeLine(this.$func.join(ids))
            .then(res => {
              indexList.forEach(index => {
                this.rowDelete(index)
              })
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.$refs.gridLayOut.selectionClear();
            })
        }
      })

    },
    headAdd() {
      if (this.$refs.gridLayOut.tableData.length == 10) {
        this.$message({
          type: "warning",
          message: "最多添加十个选项!"
        });
        return
      }
      var tableData = this.$refs.gridLayOut.tableData;
      tableData.push({
        answerOption:this.defaultValues[tableData.length],
        answerRight: '0',
        $cellEdit: true,
      })
    },
    initData() {
      getEduTree().then(res => {
        const column = this.findObject(this.$refs.formLayout.option.column, 'subjectType')
        column.dicData = res.data.data
      })
    },
    rowDelete(index) {
      this.tableData.splice(index, 1);
    },
    rowRemove(row, index) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (row.id) {
            return removeLine(row.id)
              .then(res => {
                this.rowDelete(index);
                this.$message({
                  type: "success",
                  message: "操作成功!"
                });
              });
          } else {
            this.rowDelete(index);
          }
          this.$refs.gridLayOut.selectionClear()
        })
    },
    formData(id) {
      getDetail(id).then((res) => {
        this.dataForm = this.$refs.formLayout.dataForm = res.data.data;
      });
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      getLineList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records.map(e => ({
          ...e,
          $cellEdit: true,
        }))
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    },
  },
  mounted() {
    this.initData()
  },
  created() {
    if (this.$route.query.row) {
      let row = JSON.parse(this.$route.query.row);
      this.dataForm.id = row.id;
    }
    this.type = this.$route.query.type;
    this.testPaperType = this.$route.query.testPaperType;
    if (this.type == "add") {
      this.dataForm.subjectType = this.$route.query.subjectType;
      this.dataForm.id = "";
    } else if (this.type == "edit") {
      this.query.questionId = this.dataForm.id;
      this.formData(this.dataForm.id);
      this.onLoad(this.page)
    } else if (this.type == "view") {
      this.query.questionId = this.dataForm.id;
      this.formData(this.dataForm.id);
      this.onLoad(this.page)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .avue-crud .el-table {
  height: 396px !important;
  max-height: 396px !important;
}
</style>
